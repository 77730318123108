<template>

  <b-row>

    <!-- Left -->
    <b-col
      cols="12"
      lg="9"
    >

      <b-alert
        :show="userData.role === 'admin' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        Please see the following list of requested changes.
        <ul
          class="d-flex flex-column flex-wrap mt-50"
          style="max-height: 300px;"
        >
          <li
            v-for="(value, index) in formUpdateChanges"
            :key="index"
          >
            {{ value }}
          </li>
        </ul>
        <hr>
        <div class="text-right">
          <b-button
            variant="danger"
            class="mr-1"
            @click="denyFormUpdate"
          >
            Deny
          </b-button>
          <b-button
            variant="secondary"
            @click="approveFormUpdate"
          >
            Approve
          </b-button>
        </div>
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        You still have pending profile update requests. Please wait for the Admin to review them before making any other changes.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 2"
        variant="primary"
        dismissible
        fade
        style="padding: 0.71rem 1rem; cursor: pointer; border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        The HR/Admin has approved the changes you made.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 3"
        variant="danger"
        dismissible
        fade
        style="border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        <div
          style="padding: 0.71rem 1rem; cursor: pointer;"
          @click="dismissFormUpdate"
        >
          The HR/Admin has denied the changes you made.
        </div>
      </b-alert>

      <b-overlay
        :show="userData.role === 'agent' && formUpdateStatus > 0"
        spinner-type="false"
        opacity="0.25"
        blur
        rounded
      >

        <!-- Card -->
        <b-card>

          <b-row class="hr-form">

            <b-col
              cols="12"
            >

              <b-card-title>
                Employment Application
              </b-card-title>

              <!-- Form -->
              <validation-observer ref="simpleRules">
                <b-form ref="formFieldsRef">

                  <input
                    v-model="form.id"
                    type="hidden"
                  >

                  <input
                    v-model="form.cid"
                    type="hidden"
                  >

                  <h4>PERSONAL INFORMATION</h4>
                  <b-row>
                    <b-col
                      cols="12"
                      lg="8"
                    >
                      <b-form-group
                        label="* Full Name"
                        label-for="full-name"
                      >
                        <label
                          class="sr-only"
                          for="full-name"
                        >
                          * Full Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Full Name"
                          rules="required"
                        >
                          <b-form-input
                            id="full-name"
                            v-model="fullName"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Full Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Date"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date"
                          label-for="application-date"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="application-date"
                          >
                            * Date
                          </label>
                          <flat-pickr
                            id="application-date"
                            v-model="applicationDate"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row
                    align-v="center"
                  >
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <b-form-group
                        label="Nickname"
                        label-for="nickname"
                      >
                        <label
                          class="sr-only"
                          for="nickname"
                        >
                          Nickname
                        </label>
                        <b-form-input
                          id="nickname"
                          v-model="nickname"
                          placeholder="Nickname"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <b-form-group
                        label="* Gender"
                        label-for="gender"
                      >
                        <label
                          class="sr-only"
                          for="gender"
                        >
                          * Gender
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Gender"
                          rules="required"
                        >
                          <b-form-radio-group
                            v-model="gender"
                            :options="['Male', 'Female']"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-card-text
                    class="mt-1"
                  >
                    Home Address
                  </b-card-text>
                  <b-row>
                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="* Street"
                        label-for="street"
                      >
                        <label
                          class="sr-only"
                          for="street"
                        >
                          * Street
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Street"
                          rules="required"
                        >
                          <b-form-input
                            id="street"
                            v-model="street"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Street"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b-form-group
                        label="* City"
                        label-for="city"
                      >
                        <label
                          class="sr-only"
                          for="city"
                        >
                          * City
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="City"
                          rules="required"
                        >
                          <b-form-input
                            id="city"
                            v-model="city"
                            :state="errors.length > 0 ? false : null"
                            placeholder="City"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b-form-group
                        label="* State"
                        label-for="state"
                      >
                        <label
                          class="sr-only"
                          for="state"
                        >
                          * State
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="State"
                          rules="required"
                        >
                          <b-form-input
                            id="state"
                            v-model="state"
                            :state="errors.length > 0 ? false : null"
                            placeholder="State"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b-form-group
                        label="* Zip Code"
                        label-for="zip"
                      >
                        <label
                          class="sr-only"
                          for="zip"
                        >
                          * Zip Code
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Zip Code"
                          rules="required"
                        >
                          <b-form-input
                            id="zip"
                            v-model="zip"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Zip Code"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b-form-group
                        label="Home Phone"
                        label-for="home-phone"
                      >
                        <label
                          class="sr-only"
                          for="home-phone"
                        >
                          Home Phone
                        </label>
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            US (+1)
                          </b-input-group-prepend>
                          <b-form-input
                            id="home-phone"
                            v-model="homePhone"
                            v-mask="['(###) ###-####']"
                            type="tel"
                            placeholder="Home Phone"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b-form-group
                        label="Cellphone"
                        label-for="cellphone"
                      >
                        <label
                          class="sr-only"
                          for="cellphone"
                        >
                          Cellphone
                        </label>
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            US (+1)
                          </b-input-group-prepend>
                          <b-form-input
                            id="cellphone"
                            v-model="cellphone"
                            v-mask="['###-###-####']"
                            type="tel"
                            placeholder="Cellphone"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b-form-group
                        label="Email Address"
                        label-for="email-address"
                      >
                        <label
                          class="sr-only"
                          for="email-address"
                        >
                          Email Address
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Email Address"
                          rules="required|email"
                        >
                          <b-form-input
                            id="email-address"
                            v-model="emailAddress"
                            :state="errors.length > 0 ? false : null"
                            type="email"
                            placeholder="Email Address"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <b-form-group
                        label="* Emergency Contact Person"
                        label-for="emergency-contact-person"
                      >
                        <label
                          class="sr-only"
                          for="emergency-contact-person"
                        >
                          * Emergency Contact Person
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Emergency Contact Person"
                          rules="required"
                        >
                          <b-form-input
                            id="emergency-contact-person"
                            v-model="emergencyContactPerson"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Emergency Contact Person"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <b-form-group
                        label="* Phone #"
                        label-for="emergency-contact-number"
                      >
                        <label
                          class="sr-only"
                          for="emergency-contact-number"
                        >
                          * Phone #
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Phone #"
                          rules="required"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              US (+1)
                            </b-input-group-prepend>
                            <b-form-input
                              id="emergency-contact-number"
                              v-model="emergencyContactNumber"
                              v-mask="['(###) ###-####']"
                              :state="errors.length > 0 ? false : null"
                              type="tel"
                              placeholder="Phone #"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="dob"
                        rules="required"
                      >
                        <b-form-group
                          label="* DOB"
                          label-for="dob"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="dob"
                          >
                            * DOB
                          </label>
                          <flat-pickr
                            id="dob"
                            v-model="dob"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="2"
                    >
                      <b-form-group
                        label="* Height"
                        label-for="height"
                      >
                        <label
                          class="sr-only"
                          for="height"
                        >
                          * Height
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Height"
                          rules="required"
                        >
                          <b-form-input
                            id="height"
                            v-model="height"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Height"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="2"
                    >
                      <b-form-group
                        label="* Weight"
                        label-for="weight"
                      >
                        <label
                          class="sr-only"
                          for="weight"
                        >
                          * Weight
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Weight"
                          rules="required"
                        >
                          <b-form-input
                            id="weight"
                            v-model="weight"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Weight"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b-form-group
                        label="* Marital Status"
                        label-for="marital-status"
                      >
                        <label
                          class="sr-only"
                          for="marital-status"
                        >
                          * Marital Status
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Marital Status"
                          rules="required"
                        >
                          <b-form-input
                            id="marital-status"
                            v-model="maritalStatus"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Marital Status"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b-form-group
                        label="SSN/TIN"
                        label-for="ssn-tin"
                      >
                        <label
                          class="sr-only"
                          for="ssn-tin"
                        >
                          SSN/TIN
                        </label>
                        <b-form-input
                          id="ssn-tin"
                          v-model="ssnTin"
                          placeholder="SSN/TIN"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b-form-group
                        label="* ID Type"
                        label-for="id-type"
                      >
                        <label
                          class="sr-only"
                          for="id-type"
                        >
                          * ID Type
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="ID Type"
                          rules="required"
                        >
                          <b-form-input
                            id="id-type"
                            v-model="idType"
                            :state="errors.length > 0 ? false : null"
                            placeholder="ID Type"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b-form-group
                        label="* ID No."
                        label-for="id-no"
                      >
                        <label
                          class="sr-only"
                          for="id-no"
                        >
                          * ID No.
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="ID No."
                          rules="required"
                        >
                          <b-form-input
                            id="id-no"
                            v-model="idNo"
                            :state="errors.length > 0 ? false : null"
                            placeholder="ID No."
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="ID Exp."
                        rules="required"
                      >
                        <b-form-group
                          label="* ID Exp."
                          label-for="id-exp"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="id-exp"
                          >
                            * ID Exp.
                          </label>
                          <flat-pickr
                            id="id-exp"
                            v-model="idExp"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <b-form-group
                        label="* State Issued"
                        label-for="id-no"
                      >
                        <label
                          class="sr-only"
                          for="state-issued"
                        >
                          * State Issued
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="State Issued"
                          rules="required"
                        >
                          <b-form-input
                            id="state-issued"
                            v-model="stateIssued"
                            :state="errors.length > 0 ? false : null"
                            placeholder="State Issued"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <b-form-group
                        label="* Position Applied For"
                        label-for="position-applied-for"
                      >
                        <label
                          class="sr-only"
                          for="position-applied-for"
                        >
                          * Position Applied For
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Position Applied For"
                          rules="required"
                        >
                          <b-form-checkbox-group
                            ref="positionAppliedForData"
                            v-model="form.positionAppliedForData"
                            :options="['CG', 'CNA', 'RN/LVN']"
                            :state="errors.length > 0 ? false : null"
                            title="Position Applied For"
                          />
                          <small class="text-danger"><br>{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <b-form-group
                        label="* Desired Location"
                        label-for="desired-location"
                      >
                        <label
                          class="sr-only"
                          for="desired-location"
                        >
                          * Desired Location
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Desired Location"
                          rules="required"
                        >
                          <b-form-checkbox-group
                            ref="desiredLocationData"
                            v-model="form.desiredLocationData"
                            :options="['OC', 'LA', 'San Diego', 'Other']"
                            :state="errors.length > 0 ? false : null"
                            title="Desired Location"
                          />
                          <small class="text-danger"><br>{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <b-form-group
                        label="* Desired Schedules"
                        label-for="desired-schedules"
                      >
                        <label
                          class="sr-only"
                          for="desired-schedules"
                        >
                          * Desired Schedules
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Desired Schedules"
                          rules="required"
                        >
                          <b-form-checkbox-group
                            ref="desiredSchedulesData"
                            v-model="form.desiredSchedulesData"
                            :options="['Live-In', 'Live-Out', 'Reliever', 'Weekdays', 'Weekends']"
                            :state="errors.length > 0 ? false : null"
                            title="Desired Schedules"
                          />
                          <small class="text-danger"><br>{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <b-form-group
                        label="* Transportation"
                        label-for="transportation"
                      >
                        <label
                          class="sr-only"
                          for="transportation"
                        >
                          * Transportation
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Transportation"
                          rules="required"
                        >
                          <b-form-checkbox-group
                            ref="transportationData"
                            v-model="form.transportationData"
                            :options="['Driving', 'Non-Driving', 'Own Car', 'Car Pool', 'Public']"
                            :state="errors.length > 0 ? false : null"
                            title="Transportation"
                          />
                          <small class="text-danger"><br>{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="* Language/s you speak other than English"
                        label-for="languages"
                      >
                        <label
                          class="sr-only"
                          for="languages"
                        >
                          * Language/s you speak other than English
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Languages"
                          rules="required"
                        >
                          <b-form-input
                            id="languages"
                            ref="languages"
                            v-model="form.languages"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Language/s you speak other than English"
                            title="Languages"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <b-form-group
                        label="* Highest Education Completed"
                        label-for="education"
                      >
                        <label
                          class="sr-only"
                          for="education"
                        >
                          * Highest Education Completed
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Highest Education Completed"
                          rules="required"
                        >
                          <b-form-input
                            id="education"
                            ref="education"
                            v-model="form.education"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Highest Education Completed"
                            title="Highest Education Completed"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <b-form-group
                        label="* School"
                        label-for="school"
                      >
                        <label
                          class="sr-only"
                          for="school"
                        >
                          * School
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="School"
                          rules="required"
                        >
                          <b-form-input
                            id="school"
                            ref="school"
                            v-model="form.school"
                            :state="errors.length > 0 ? false : null"
                            placeholder="School"
                            title="school"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <b-form-group
                        label="* Degree(s)"
                        label-for="degree"
                      >
                        <label
                          class="sr-only"
                          for="degree"
                        >
                          * Degree(s)
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Degree(s)"
                          rules="required"
                        >
                          <b-form-input
                            id="degree"
                            ref="degree"
                            v-model="form.degree"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Degree(s)"
                            title="Degree(s)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <b-form-group
                        label="* Degree(s) From"
                        label-for="degree-from"
                      >
                        <label
                          class="sr-only"
                          for="degree-from"
                        >
                          * Degree(s) From
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Degree(s) From"
                          rules="required"
                        >
                          <b-form-input
                            id="degree-from"
                            ref="degreeFrom"
                            v-model="form.degreeFrom"
                            :state="errors.length > 0 ? false : null"
                            placeholder="From"
                            title="Degree(s) From"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b-form-group
                        label="* Special Related Education/Trainings"
                        label-for="training"
                      >
                        <label
                          class="sr-only"
                          for="training"
                        >
                          * Special Related Education/Trainings
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Education/Trainings"
                          rules="required"
                        >
                          <b-form-input
                            id="training"
                            ref="training"
                            v-model="form.training"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Special Related Education/Trainings"
                            title="Special Related Education/Trainings"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="Please attach certificates if available"
                        label-for="cert-attachment"
                      >
                        <label
                          class="sr-only"
                          for="cert-attachment"
                        >
                          Please attach certificates if available
                        </label>
                        <b-form-file
                          ref="educationAttachments"
                          v-model="form.educationAttachments"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                          type="file"
                          accept="image/*"
                          multiple
                          capture
                          title="Certificate(s)"
                        >
                          <template
                            slot="file-name"
                            slot-scope="{ names }"
                          >
                            <b-badge variant="primary">
                              {{ names[0] }}
                            </b-badge>
                            <b-badge
                              v-if="names.length > 1"
                              variant="dark"
                              class="ml-1"
                            >
                              + {{ names.length - 1 }} More files
                            </b-badge>
                          </template>
                        </b-form-file>
                      </b-form-group>

                      <b-list-group v-if="educationAttachmentsFile">

                        <b-list-group-item
                          v-for="(item, index) in educationAttachmentsFile"
                          :key="item"
                          class="d-flex justify-content-between text-truncate"
                        >

                          <span class="d-inline-block text-truncate mr-1">
                            <b-link
                              :href="`${urlUpload}${id}/${item}`"
                              target="_blank"
                              class="font-weight-bold pointer-events-auto"
                            >
                              {{ item }}
                            </b-link>
                          </span>

                          <span class="d-inline-block">
                            <feather-icon
                              :id="`row-${index}-cert-attachment-preview-icon`"
                              icon="EyeIcon"
                              size="16"
                              class="mr-1 pointer-events-auto"
                              role="button"
                              @click="previewFormFile({ id: id, item: item })"
                            />
                            <b-tooltip
                              title="Preview"
                              :target="`row-${index}-cert-attachment-preview-icon`"
                            />

                            <feather-icon
                              :id="`row-${index}-cert-attachment-delete-icon`"
                              icon="TrashIcon"
                              size="16"
                              role="button"
                              @click="deleteFormFile({ id: id, educationAttachments: item })"
                            />
                            <b-tooltip
                              title="Delete"
                              :target="`row-${index}-cert-attachment-delete-icon`"
                            />
                          </span>
                        </b-list-group-item>
                      </b-list-group>

                    </b-col>
                  </b-row>
                  <hr>
                  <h4>PROFESSIONAL EXPERIENCE</h4>
                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <b-form-group
                        label="* # of Years with Current Employer"
                        label-for="years-current-employer"
                      >
                        <label
                          class="sr-only"
                          for="years-current-employer"
                        >
                          * # of Years with Current Employer
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="# of Years with Current Employer"
                          rules="required"
                        >
                          <b-form-input
                            id="years-current-employer"
                            ref="yearsCurrentEmployer"
                            v-model="form.yearsCurrentEmployer"
                            v-mask="['##']"
                            :state="errors.length > 0 ? false : null"
                            placeholder="# of Years with Current Employer"
                            title="# of Years with Current Employer"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <b-form-group
                        label="* # of Years in this Industry"
                        label-for="years-industry"
                      >
                        <label
                          class="sr-only"
                          for="years-industry"
                        >
                          * # of Years in this Industry
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="# of Years in this Industry"
                          rules="required"
                        >
                          <b-form-input
                            id="years-industry"
                            ref="yearsIndustry"
                            v-model="form.yearsIndustry"
                            v-mask="['##']"
                            :state="errors.length > 0 ? false : null"
                            placeholder="# of Years in this Industry"
                            title="# of Years in this Industry"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-card-text
                    class="mt-1"
                  >
                    Employment History (Beginning with Current Job)
                  </b-card-text>
                  <!-- 1st Employment History -->
                  <b-row>
                    <b-col
                      cols="12"
                      lg="2"
                    >
                      <b-form-group
                        label="From"
                        label-for="history-from1"
                      >
                        <label
                          class="sr-only"
                          for="history-from1"
                        >
                          From
                        </label>
                        <b-input-group class="flatpickr">
                          <flat-pickr
                            id="history-from1"
                            ref="historyFrom1"
                            v-model="form.historyFrom1"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="From (1st Employment History)"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-dark"
                              title="Clear"
                              class="brdr-gray px-1"
                              data-clear
                            >
                              <i class="fa fa-times">
                                <span
                                  aria-hidden="true"
                                  class="sr-only"
                                >
                                  Clear
                                </span>
                              </i>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="2"
                    >
                      <b-form-group
                        label="To"
                        label-for="history-to1"
                      >
                        <label
                          class="sr-only"
                          for="history-to1"
                        >
                          to
                        </label>
                        <b-input-group class="flatpickr">
                          <flat-pickr
                            id="history-to1"
                            ref="historyTo1"
                            v-model="form.historyTo1"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="To (1st Employment History)"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-dark"
                              title="Clear"
                              class="brdr-gray px-1"
                              data-clear
                            >
                              <i class="fa fa-times">
                                <span
                                  aria-hidden="true"
                                  class="sr-only"
                                >
                                  Clear
                                </span>
                              </i>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b-form-group
                        label="Company"
                        label-for="history-company1"
                      >
                        <label
                          class="sr-only"
                          for="history-company1"
                        >
                          Company
                        </label>
                        <b-form-input
                          id="history-company1"
                          ref="historyCompany1"
                          v-model="form.historyCompany1"
                          placeholder="Company"
                          title="Company (1st Employment History)"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b-form-group
                        label="Position"
                        label-for="history-position1"
                      >
                        <label
                          class="sr-only"
                          for="history-company1"
                        >
                          Position
                        </label>
                        <b-form-input
                          id="history-position1"
                          ref="historyPosition1"
                          v-model="form.historyPosition1"
                          placeholder="Position"
                          title="Position (1st Employment History)"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <b-form-group
                        label="Address"
                        label-for="history-address1"
                      >
                        <label
                          class="sr-only"
                          for="history-address1"
                        >
                          Address
                        </label>
                        <b-form-input
                          id="history-address1"
                          ref="historyAddress1"
                          v-model="form.historyAddress1"
                          placeholder="Address"
                          title="Address (1st Employment History)"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      <b-form-group
                        label="Phone"
                        label-for="history-phone1"
                      >
                        <label
                          class="sr-only"
                          for="history-phone1"
                        >
                          Phone
                        </label>
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            US (+1)
                          </b-input-group-prepend>
                          <b-form-input
                            id="history-phone1"
                            ref="historyPhone1"
                            v-model="form.historyPhone1"
                            v-mask="['(###) ###-####']"
                            type="tel"
                            placeholder="1234 567 8900"
                            title="Phone (1st Employment History)"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      <b-form-group
                        label="Fax"
                        label-for="history-fax1"
                      >
                        <label
                          class="sr-only"
                          for="history-fax1"
                        >
                          Fax
                        </label>
                        <b-form-input
                          id="history-fax1"
                          ref="historyFax1"
                          v-model="form.historyFax1"
                          placeholder="Fax"
                          title="Fax (1st Employment History)"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="Responsibilities"
                        label-for="history-responsibilities1"
                      >
                        <label
                          class="sr-only"
                          for="history-responsibilities1"
                        >
                          Responsibilities
                        </label>
                        <b-form-input
                          id="history-responsibilities1"
                          ref="historyResponsibilities1"
                          v-model="form.historyResponsibilities1"
                          placeholder="Responsibilities"
                          title="Responsibilities (1st Employment History)"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <!-- 2nd Employment History -->
                  <hr>
                  <b-row>
                    <b-col
                      cols="12"
                      lg="2"
                    >
                      <b-form-group
                        label="From"
                        label-for="history-from2"
                      >
                        <label
                          class="sr-only"
                          for="history-from2"
                        >
                          From
                        </label>
                        <b-input-group class="flatpickr">
                          <flat-pickr
                            id="history-from2"
                            ref="historyFrom2"
                            v-model="form.historyFrom2"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="From (2nd Employment History)"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-dark"
                              title="Clear"
                              class="brdr-gray px-1"
                              data-clear
                            >
                              <i class="fa fa-times">
                                <span
                                  aria-hidden="true"
                                  class="sr-only"
                                >
                                  Clear
                                </span>
                              </i>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="2"
                    >
                      <b-form-group
                        label="To"
                        label-for="history-to2"
                      >
                        <label
                          class="sr-only"
                          for="history-to2"
                        >
                          To
                        </label>
                        <b-input-group class="flatpickr">
                          <flat-pickr
                            id="history-to2"
                            ref="historyTo2"
                            v-model="form.historyTo2"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="To (2nd Employment History)"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-dark"
                              title="Clear"
                              class="brdr-gray px-1"
                              data-clear
                            >
                              <i class="fa fa-times">
                                <span
                                  aria-hidden="true"
                                  class="sr-only"
                                >
                                  Clear
                                </span>
                              </i>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b-form-group
                        label="Company"
                        label-for="history-company2"
                      >
                        <label
                          class="sr-only"
                          for="history-company2"
                        >
                          Company
                        </label>
                        <b-form-input
                          id="history-company2"
                          ref="historyCompany2"
                          v-model="form.historyCompany2"
                          placeholder="Company"
                          title="Company (2nd Employment History)"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b-form-group
                        label="Position"
                        label-for="history-position2"
                      >
                        <label
                          class="sr-only"
                          for="history-company2"
                        >
                          Position
                        </label>
                        <b-form-input
                          id="history-position2"
                          ref="historyPosition2"
                          v-model="form.historyPosition2"
                          placeholder="Position"
                          title="Position (2nd Employment History)"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <b-form-group
                        label="Address"
                        label-for="history-address2"
                      >
                        <label
                          class="sr-only"
                          for="history-address2"
                        >
                          Address
                        </label>
                        <b-form-input
                          id="history-address2"
                          ref="historyAddress2"
                          v-model="form.historyAddress2"
                          placeholder="Address"
                          title="Address (2nd Employment History)"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      <b-form-group
                        label="Phone"
                        label-for="history-phone2"
                      >
                        <label
                          class="sr-only"
                          for="history-phone2"
                        >
                          Phone
                        </label>
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            US (+1)
                          </b-input-group-prepend>
                          <b-form-input
                            id="history-phone2"
                            ref="historyPhone2"
                            v-model="form.historyPhone2"
                            v-mask="['(###) ###-####']"
                            type="tel"
                            placeholder="1234 567 8900"
                            title="Phone (2nd Employment History)"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      <b-form-group
                        label="Fax"
                        label-for="history-fax2"
                      >
                        <label
                          class="sr-only"
                          for="history-fax2"
                        >
                          Fax
                        </label>
                        <b-form-input
                          id="history-fax2"
                          ref="historyFax2"
                          v-model="form.historyFax2"
                          placeholder="Fax"
                          title="Fax (2nd Employment History)"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="Responsibilities"
                        label-for="history-responsibilities2"
                      >
                        <label
                          class="sr-only"
                          for="history-responsibilities2"
                        >
                          Responsibilities
                        </label>
                        <b-form-input
                          id="history-responsibilities2"
                          ref="historyResponsibilities2"
                          v-model="form.historyResponsibilities2"
                          placeholder="Responsibilities"
                          title="Responsibilities (2nd Employment History)"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <!-- 3rd Employment History -->
                  <hr>
                  <b-row>
                    <b-col
                      cols="12"
                      lg="2"
                    >
                      <b-form-group
                        label="From"
                        label-for="history-from3"
                      >
                        <label
                          class="sr-only"
                          for="history-from3"
                        >
                          From
                        </label>
                        <b-input-group class="flatpickr">
                          <flat-pickr
                            id="history-from3"
                            ref="historyFrom3"
                            v-model="form.historyFrom3"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="From (3rd Employment History)"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-dark"
                              title="Clear"
                              class="brdr-gray px-1"
                              data-clear
                            >
                              <i class="fa fa-times">
                                <span
                                  aria-hidden="true"
                                  class="sr-only"
                                >
                                  Clear
                                </span>
                              </i>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="2"
                    >
                      <b-form-group
                        label="To"
                        label-for="history-to3"
                      >
                        <label
                          class="sr-only"
                          for="history-to3"
                        >
                          To
                        </label>
                        <b-input-group class="flatpickr">
                          <flat-pickr
                            id="history-to3"
                            ref="historyTo3"
                            v-model="form.historyTo3"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="To (3rd Employment History)"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-dark"
                              title="Clear"
                              class="brdr-gray px-1"
                              data-clear
                            >
                              <i class="fa fa-times">
                                <span
                                  aria-hidden="true"
                                  class="sr-only"
                                >
                                  Clear
                                </span>
                              </i>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b-form-group
                        label="Company"
                        label-for="history-company3"
                      >
                        <label
                          class="sr-only"
                          for="history-company3"
                        >
                          Company
                        </label>
                        <b-form-input
                          id="history-company3"
                          ref="historyCompany3"
                          v-model="form.historyCompany3"
                          placeholder="Company"
                          title="Company (3rd Employment History)"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b-form-group
                        label="Position"
                        label-for="history-position3"
                      >
                        <label
                          class="sr-only"
                          for="history-company3"
                        >
                          Position
                        </label>
                        <b-form-input
                          id="history-position3"
                          ref="historyPosition3"
                          v-model="form.historyPosition3"
                          placeholder="Position"
                          title="Position (3rd Employment History)"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <b-form-group
                        label="Address"
                        label-for="history-address3"
                      >
                        <label
                          class="sr-only"
                          for="history-address3"
                        >
                          Address
                        </label>
                        <b-form-input
                          id="history-address3"
                          ref="historyAddress3"
                          v-model="form.historyAddress3"
                          placeholder="Address"
                          title="Address (3rd Employment History)"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      <b-form-group
                        label="Phone"
                        label-for="history-phone3"
                      >
                        <label
                          class="sr-only"
                          for="history-phone3"
                        >
                          Phone
                        </label>
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            US (+1)
                          </b-input-group-prepend>
                          <b-form-input
                            id="history-phone3"
                            ref="historyPhone3"
                            v-model="form.historyPhone3"
                            v-mask="['(###) ###-####']"
                            type="tel"
                            placeholder="1234 567 8900"
                            title="Phone (3rd Employment History)"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      <b-form-group
                        label="Fax"
                        label-for="history-fax3"
                      >
                        <label
                          class="sr-only"
                          for="history-fax3"
                        >
                          Fax
                        </label>
                        <b-form-input
                          id="history-fax3"
                          ref="historyFax3"
                          v-model="form.historyFax3"
                          placeholder="Fax"
                          title="Fax (3rd Employment History)"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="Responsibilities"
                        label-for="history-responsibilities3"
                      >
                        <label
                          class="sr-only"
                          for="history-responsibilities3"
                        >
                          Responsibilities
                        </label>
                        <b-form-input
                          id="history-responsibilities3"
                          ref="historyResponsibilities3"
                          v-model="form.historyResponsibilities3"
                          placeholder="Responsibilities"
                          title="Responsibilities (3rd Employment History)"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <hr>
                  <h4>PROFESSIONAL and PERSONAL REFERENCES (Past or Present Employers or Managers)</h4>
                  <!-- 1st Reference -->
                  <b-row>
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      <b-form-group
                        label="Name"
                        label-for="ref-name1"
                      >
                        <label
                          class="sr-only"
                          for="ref-name1"
                        >
                          Name
                        </label>
                        <b-form-input
                          id="ref-name1"
                          ref="refName1"
                          v-model="form.refName1"
                          placeholder="Name"
                          title="Name (1st PROFESSIONAL and PERSONAL REFERENCES)"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      <b-form-group
                        label="Position"
                        label-for="ref-position1"
                      >
                        <label
                          class="sr-only"
                          for="ref-position1"
                        >
                          Position
                        </label>
                        <b-form-input
                          id="ref-position1"
                          ref="refPosition1"
                          v-model="form.refPosition1"
                          placeholder="Position"
                          title="Position (1st PROFESSIONAL and PERSONAL REFERENCES)"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      <b-form-group
                        label="Company"
                        label-for="ref-company1"
                      >
                        <label
                          class="sr-only"
                          for="ref-company1"
                        >
                          Company
                        </label>
                        <b-form-input
                          id="ref-company1"
                          ref="refCompany1"
                          v-model="form.refCompany1"
                          placeholder="Company"
                          title="Company (1st PROFESSIONAL and PERSONAL REFERENCES)"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      <b-form-group
                        label="Phone No."
                        label-for="ref-phone-no1"
                      >
                        <label
                          class="sr-only"
                          for="ref-phone-no1"
                        >
                          Phone No.
                        </label>
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            US (+1)
                          </b-input-group-prepend>
                          <b-form-input
                            id="ref-phone-no1"
                            ref="refPhoneNo1"
                            v-model="form.refPhoneNo1"
                            v-mask="['(###) ###-####']"
                            type="tel"
                            placeholder="1234 567 8900"
                            title="Phone No. (1st PROFESSIONAL and PERSONAL REFERENCES)"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <!-- 2nd Reference -->
                  <hr>
                  <b-row>
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      <b-form-group
                        label="Name"
                        label-for="ref-name2"
                      >
                        <label
                          class="sr-only"
                          for="ref-name2"
                        >
                          Name
                        </label>
                        <b-form-input
                          id="ref-name2"
                          ref="refName2"
                          v-model="form.refName2"
                          placeholder="Name"
                          title="Name (2nd PROFESSIONAL and PERSONAL REFERENCES)"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      <b-form-group
                        label="Position"
                        label-for="ref-position2"
                      >
                        <label
                          class="sr-only"
                          for="ref-position2"
                        >
                          Position
                        </label>
                        <b-form-input
                          id="ref-position2"
                          ref="refPosition2"
                          v-model="form.refPosition2"
                          placeholder="Position"
                          title="Position (2nd PROFESSIONAL and PERSONAL REFERENCES)"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      <b-form-group
                        label="Company"
                        label-for="ref-company2"
                      >
                        <label
                          class="sr-only"
                          for="ref-company2"
                        >
                          Company
                        </label>
                        <b-form-input
                          id="ref-company2"
                          ref="refCompany2"
                          v-model="form.refCompany2"
                          placeholder="Company"
                          title="Company (2nd PROFESSIONAL and PERSONAL REFERENCES)"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      <b-form-group
                        label="Phone No."
                        label-for="ref-phone-no2"
                      >
                        <label
                          class="sr-only"
                          for="ref-phone-no2"
                        >
                          Phone No.
                        </label>
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            US (+1)
                          </b-input-group-prepend>
                          <b-form-input
                            id="ref-phone-no2"
                            ref="refPhoneNo2"
                            v-model="form.refPhoneNo2"
                            v-mask="['(###) ###-####']"
                            type="tel"
                            placeholder="1234 567 8900"
                            title="Phone No. (2nd PROFESSIONAL and PERSONAL REFERENCES)"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <!-- 3rd Reference -->
                  <hr>
                  <b-row>
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      <b-form-group
                        label="Name"
                        label-for="ref-name3"
                      >
                        <label
                          class="sr-only"
                          for="ref-name3"
                        >
                          Name
                        </label>
                        <b-form-input
                          id="ref-name3"
                          ref="refName3"
                          v-model="form.refName3"
                          placeholder="Name"
                          title="Name (3rd PROFESSIONAL and PERSONAL REFERENCES)"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      <b-form-group
                        label="Position"
                        label-for="ref-position3"
                      >
                        <label
                          class="sr-only"
                          for="ref-position3"
                        >
                          Position
                        </label>
                        <b-form-input
                          id="ref-position3"
                          ref="refPosition3"
                          v-model="form.refPosition3"
                          placeholder="Position"
                          title="Position (3rd PROFESSIONAL and PERSONAL REFERENCES)"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      <b-form-group
                        label="Company"
                        label-for="ref-company3"
                      >
                        <label
                          class="sr-only"
                          for="ref-company3"
                        >
                          Company
                        </label>
                        <b-form-input
                          id="ref-company3"
                          ref="refCompany3"
                          v-model="form.refCompany3"
                          placeholder="Company"
                          title="Company (3rd PROFESSIONAL and PERSONAL REFERENCES)"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      <b-form-group
                        label="Phone No."
                        label-for="ref-phone-no3"
                      >
                        <label
                          class="sr-only"
                          for="ref-phone-no3"
                        >
                          Phone No.
                        </label>
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            US (+1)
                          </b-input-group-prepend>
                          <b-form-input
                            id="ref-phone-no3"
                            ref="refPhoneNo3"
                            v-model="form.refPhoneNo3"
                            v-mask="['(###) ###-####']"
                            type="tel"
                            placeholder="1234 567 8900"
                            title="Phone No. (3rd PROFESSIONAL and PERSONAL REFERENCES)"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <hr>
                  <b-row>
                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="Who referred you to our company?"
                        label-for="who-ref"
                      >
                        <label
                          class="sr-only"
                          for="who-ref"
                        >
                          Who referred you to our company?
                        </label>
                        <b-form-input
                          id="who-ref"
                          ref="whoRef"
                          v-model="form.whoRef"
                          placeholder="Who referred you to our company?"
                          title="Referral"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <h4>CREDENTIAL / CERTIFICATIONS</h4>
                  <b-row>
                    <b-col
                      cols="12"
                      lg="8"
                    >
                      <b-row>
                        <b-col>
                          <b-form-group
                            label="* Credential / Certifications"
                            label-for="credential-data"
                          >
                            <label
                              class="sr-only"
                              for="credential-data"
                            >
                              * Credential / Certifications
                            </label>
                            <validation-provider
                              #default="{ errors }"
                              name="Credential / Certifications"
                              rules="required"
                            >
                              <b-form-checkbox-group
                                ref="credentialData"
                                v-model="form.credentialData"
                                :options="['HCA', 'HHA', 'CNA', 'RN/LVN']"
                                :state="errors.length > 0 ? false : null"
                                title="Credential / Certifications"
                              />
                              <small class="text-danger"><br>{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row :hidden="form.credentialData !== undefined ? form.credentialData.indexOf('HCA') === -1 : true">
                        <b-col>
                          <b-form-group
                            label="HCA License No."
                            label-for="license-no"
                          >
                            <label
                              class="sr-only"
                              for="license-no"
                            >
                              HCA License No.
                            </label>
                            <b-form-input
                              id="license-no"
                              ref="licenseNoHCA"
                              v-model="form.licenseNoHCA"
                              placeholder="HCA License No."
                              title="HCA License No."
                            />
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            label="Expiration"
                            label-for="expiration"
                          >
                            <label
                              class="sr-only"
                              for="expiration"
                            >
                              Expiration
                            </label>
                            <b-input-group class="flatpickr">
                              <flat-pickr
                                id="expiration"
                                ref="expirationHCA"
                                v-model="form.expirationHCA"
                                class="form-control"
                                :config="flatPickrConfig"
                                placeholder="Choose a date"
                                title="HCA Expiration"
                              />
                              <b-input-group-append>
                                <b-button
                                  variant="outline-dark"
                                  title="Clear"
                                  class="brdr-gray px-1"
                                  data-clear
                                >
                                  <i class="fa fa-times">
                                    <span
                                      aria-hidden="true"
                                      class="sr-only"
                                    >
                                      Clear
                                    </span>
                                  </i>
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row :hidden="form.credentialData !== undefined ? form.credentialData.indexOf('HHA') === -1 : true">
                        <b-col>
                          <b-form-group
                            label="HHA License No."
                            label-for="license-no"
                          >
                            <label
                              class="sr-only"
                              for="license-no"
                            >
                              HHA License No.
                            </label>
                            <b-form-input
                              id="license-no"
                              ref="licenseNoHHA"
                              v-model="form.licenseNoHHA"
                              placeholder="HHA License No."
                              title="HHA License No."
                            />
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            label="Expiration"
                            label-for="expiration"
                          >
                            <label
                              class="sr-only"
                              for="expiration"
                            >
                              Expiration
                            </label>
                            <b-input-group class="flatpickr">
                              <flat-pickr
                                id="expiration"
                                ref="expirationHHA"
                                v-model="form.expirationHHA"
                                class="form-control"
                                :config="flatPickrConfig"
                                placeholder="Choose a date"
                                title="HHA Expiration"
                              />
                              <b-input-group-append>
                                <b-button
                                  variant="outline-dark"
                                  title="Clear"
                                  class="brdr-gray px-1"
                                  data-clear
                                >
                                  <i class="fa fa-times">
                                    <span
                                      aria-hidden="true"
                                      class="sr-only"
                                    >
                                      Clear
                                    </span>
                                  </i>
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row :hidden="form.credentialData !== undefined ? form.credentialData.indexOf('CNA') === -1 : true">
                        <b-col>
                          <b-form-group
                            label="CNA License No."
                            label-for="license-no"
                          >
                            <label
                              class="sr-only"
                              for="license-no"
                            >
                              CNA License No.
                            </label>
                            <b-form-input
                              id="license-no"
                              ref="licenseNoCNA"
                              v-model="form.licenseNoCNA"
                              placeholder="CNA License No."
                              title="CNA License No."
                            />
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            label="Expiration"
                            label-for="expiration"
                          >
                            <label
                              class="sr-only"
                              for="expiration"
                            >
                              Expiration
                            </label>
                            <b-input-group class="flatpickr">
                              <flat-pickr
                                id="expiration"
                                ref="expirationCNA"
                                v-model="form.expirationCNA"
                                class="form-control"
                                :config="flatPickrConfig"
                                placeholder="Choose a date"
                                title="CNA Expiration"
                              />
                              <b-input-group-append>
                                <b-button
                                  variant="outline-dark"
                                  title="Clear"
                                  class="brdr-gray px-1"
                                  data-clear
                                >
                                  <i class="fa fa-times">
                                    <span
                                      aria-hidden="true"
                                      class="sr-only"
                                    >
                                      Clear
                                    </span>
                                  </i>
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row :hidden="form.credentialData !== undefined ? form.credentialData.indexOf('RN/LVN') === -1 : true">
                        <b-col>
                          <b-form-group
                            label="RN/VLN License No."
                            label-for="license-no"
                          >
                            <label
                              class="sr-only"
                              for="license-no"
                            >
                              RN/VLN License No.
                            </label>
                            <b-form-input
                              id="license-no"
                              ref="licenseNoRNLVN"
                              v-model="form.licenseNoRNLVN"
                              placeholder="RN/VLN License No."
                              title="RN/VLN License No."
                            />
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            label="Expiration"
                            label-for="expiration"
                          >
                            <label
                              class="sr-only"
                              for="expiration"
                            >
                              Expiration
                            </label>
                            <b-input-group class="flatpickr">
                              <flat-pickr
                                id="expiration"
                                ref="expirationRNLVN"
                                v-model="form.expirationRNLVN"
                                class="form-control"
                                :config="flatPickrConfig"
                                placeholder="Choose a date"
                                title="RN/VLN Expiration"
                              />
                              <b-input-group-append>
                                <b-button
                                  variant="outline-dark"
                                  title="Clear"
                                  class="brdr-gray px-1"
                                  data-clear
                                >
                                  <i class="fa fa-times">
                                    <span
                                      aria-hidden="true"
                                      class="sr-only"
                                    >
                                      Clear
                                    </span>
                                  </i>
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b-row>
                        <b-col>
                          <b-form-group
                            label="* Application Accepted by"
                            label-for="application-accepted-by"
                          >
                            <label
                              class="sr-only"
                              for="application-accepted-by"
                            >
                              * Application Accepted by
                            </label>
                            <validation-provider
                              #default="{ errors }"
                              name="Application Accepted by"
                              rules="required"
                            >
                              <b-form-input
                                id="application-accepted-by"
                                ref="applicationAcceptedBy"
                                v-model="form.applicationAcceptedBy"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Application Accepted by"
                                title="Application Accepted by"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form-group
                            label="* Interviewed by"
                            label-for="interviewed-by"
                          >
                            <label
                              class="sr-only"
                              for="interviewed-by"
                            >
                              * Interviewed by
                            </label>
                            <validation-provider
                              #default="{ errors }"
                              name="Interviewed by"
                              rules="required"
                            >
                              <b-form-input
                                id="interviewed-by"
                                ref="interviewedBy"
                                v-model="form.interviewedBy"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Interviewed by"
                                title="Interviewed by"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-button
                    variant="primary"
                    type="submit"
                    class="d-none"
                    @click.prevent="validationForm"
                  >
                    Test Btn
                  </b-button>
                </b-form>
              </validation-observer>

            </b-col>
          </b-row>

        </b-card>

      </b-overlay>
    </b-col>

    <!-- Right -->
    <b-col
      cols="12"
      lg="3"
    >

      <!-- Action Buttons -->
      <b-card>

        <!-- Button Preview -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="gradient-main"
          class="mb-75 btn-print-preview"
          block
          @click="$store.dispatch('app-hr/preview')"
        >
          Print Preview
        </b-button>

        <!-- Button Save -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          block
          :disabled="(userData.role === 'agent' && formUpdateStatus > 0) ? true : isNotSaved"
          @click.prevent="validationForm"
        >
          Save
        </b-button>

        <!-- Button Cancel -->
        <b-button
          v-if="action !== 'preview'"
          variant="outline-dark"
          class="mb-75"
          :to="{ name: (userData.role === 'agent' ? 'caregiverLanding' : 'hr') }"
          block
        >
          Cancel
        </b-button>

        <div class="d-flex">
          <!-- Button Previous -->
          <b-button
            variant="outline-dark"
            class="flex-fill mr-25"
            :to="{ name: 'apps-hr-form1', params: { action: action }, query: { id: id } }"
          >
            Previous
          </b-button>

          <!-- Button Next -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            class="flex-fill ml-25"
            :to="{ name: 'apps-hr-form3', params: { action: action }, query: { id: id } }"
          >
            Next
          </b-button>
        </div>

        <!-- Spacer -->
        <hr class="mb-50">

        <label for="forms">Form Selection</label>
        <v-select
          id="forms"
          v-model="forms"
          :reduce="(option) => option.name"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="formsOptions"
          :clearable="false"
          :disabled="!id"
          class="hr-forms-select"
          @input="$router.push({ name: forms, params: { action: action }, query: { id: id } })"
        >
          <template #selected-option="{ label }">
            <span class="text-truncate overflow-hidden">
              {{ label }}
            </span>
          </template>
        </v-select>
      </b-card>

    </b-col>
  </b-row>

</template>

<script>
import SockJS from 'sockjs-client'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BOverlay, BAlert, BCard, BCardTitle, BCardText, BRow, BCol, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormFile, BButton, BFormCheckboxGroup, BFormRadioGroup, VBToggle, BTooltip, BBadge, BListGroup, BListGroupItem, BLink,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { mask } from 'vue-the-mask'
import Swal from 'sweetalert2'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import { mapState } from 'vuex'

import { getUserData } from '@/auth/utils'

import hrStoreModule from '../hrStoreModule'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BOverlay,
    BAlert,
    BCard,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormFile,
    BButton,
    BFormCheckboxGroup,
    BFormRadioGroup,
    BBadge,
    BTooltip,
    BListGroup,
    BListGroupItem,
    BLink,

    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    mask,
  },
  setup() {
    const HR_APP_STORE_MODULE_NAME = 'app-hr'

    // Register module
    if (!store.hasModule(HR_APP_STORE_MODULE_NAME)) store.registerModule(HR_APP_STORE_MODULE_NAME, hrStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HR_APP_STORE_MODULE_NAME)) store.unregisterModule(HR_APP_STORE_MODULE_NAME)
    })

    const form = ref({})

    const educationAttachmentsFile = ref([])

    const fullName = ref('')
    const applicationDate = ref('')
    const nickname = ref('')
    const gender = ref('')
    const street = ref('')
    const city = ref('')
    const state = ref('')
    const zip = ref('')
    const homePhone = ref('')
    const cellphone = ref('')
    const emailAddress = ref('')
    const emergencyContactPerson = ref('')
    const emergencyContactNumber = ref('')
    const dob = ref('')
    const height = ref('')
    const weight = ref('')
    const maritalStatus = ref('')
    const ssnTin = ref('')
    const idType = ref('')
    const idNo = ref('')
    const idExp = ref('')
    const stateIssued = ref('')

    const input = ref(true)

    const formUpdate = ref({})
    const formUpdateStatus = ref(0)

    const formCopy = ref({})

    if (router.currentRoute.query.id) {
      store.dispatch('app-hr/fetchForm', { id: router.currentRoute.query.id })
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          form.value = {}

          form.value.cid = router.currentRoute.query.id

          // eslint-disable-next-line
          fullName.value = jsonObjectLiterals.data.firstName + ' ' + jsonObjectLiterals.data.lastName
          applicationDate.value = jsonObjectLiterals.data.hireDate
          nickname.value = jsonObjectLiterals.data.akaFirstName
          gender.value = jsonObjectLiterals.data.sex
          street.value = jsonObjectLiterals.data.street
          city.value = jsonObjectLiterals.data.cityTown
          state.value = jsonObjectLiterals.data.state
          zip.value = jsonObjectLiterals.data.zipCode
          homePhone.value = jsonObjectLiterals.data.phoneNumber
          cellphone.value = jsonObjectLiterals.data.mobileNumber
          emailAddress.value = jsonObjectLiterals.data.emailAddress
          emergencyContactPerson.value = jsonObjectLiterals.data.emergencyContactName
          emergencyContactNumber.value = jsonObjectLiterals.data.emergencyContactNumber
          dob.value = jsonObjectLiterals.data.dateOfBirth
          height.value = jsonObjectLiterals.data.height
          weight.value = jsonObjectLiterals.data.weight
          maritalStatus.value = jsonObjectLiterals.data.maritalStatus
          ssnTin.value = (jsonObjectLiterals.data.ssn !== '' ? jsonObjectLiterals.data.ssn : jsonObjectLiterals.data.tin)
          idType.value = jsonObjectLiterals.data.idType
          idNo.value = jsonObjectLiterals.data.idNumber
          idExp.value = jsonObjectLiterals.data.idExpirationDate
          stateIssued.value = jsonObjectLiterals.data.idStateIssued

          if (router.currentRoute.params.action === 'edit' || (router.currentRoute.params.action === 'add' && router.currentRoute.query.id)) {
            store.dispatch('app-hr/fetchForm2', { cid: router.currentRoute.query.id })
              .then(response1 => {
                // eslint-disable-next-line
                const jsonObjectLiterals = response1.data.response.data

                if (jsonObjectLiterals.data.cid) {
                  form.value = jsonObjectLiterals.data
                }

                educationAttachmentsFile.value = jsonObjectLiterals.data.educationAttachments

                if (form.value.id) {
                  form.value.form = 'folder3_form2'

                  formCopy.value = JSON.parse(JSON.stringify(form.value))

                  store.dispatch('app-hr/fetchFormUpdate', { form: form.value.form, form_id: form.value.id })
                    .then(response2 => {
                      // eslint-disable-next-line
                      const jsonObjectLiterals = response2.data.response.data

                      // console.log(jsonObjectLiterals.data)

                      if (jsonObjectLiterals.data.status) {
                        formUpdate.value = jsonObjectLiterals.data

                        formUpdateStatus.value = formUpdate.value.status
                      }
                    })
                    .catch(error => {
                      console.error(error)
                    })
                }
              })
              .catch(error => {
                console.error(error)
              })
          }
        })
        .catch(error => {
          console.error(error)
        })
    }

    const userData = ref(getUserData())

    const formsOptions = [
      { label: 'Basic Information', name: 'apps-hr-form' },
      { label: 'Employment Checklist', name: 'apps-hr-form1' },
      { label: 'Employment Application', name: 'apps-hr-form2' },
      { label: 'Employment Skills List', name: 'apps-hr-form3' },
      { label: 'Work Experiences Checklist', name: 'apps-hr-form4' },
      { label: 'Employment Eligibility Verification', name: 'apps-hr-form5' },
      { label: 'Form W-4', name: 'apps-hr-form6' },
      { label: 'Non-Compete and Non-Solicitation Agreement', name: 'apps-hr-form7' },
      { label: 'Employment Verification Fax', name: 'apps-hr-form8' },
      { label: 'Ref Inquiry Form', name: 'apps-hr-form9' },
      { label: 'Request for Live Scan Service - Community Care Licensing', name: 'apps-hr-form10' },
      { label: 'New Hire Orientation Acknowledgment Form', name: 'apps-hr-form11' },
      { label: 'Employee Tuberculin Skin Test (TST) and Evaluation', name: 'apps-hr-form12' },
      { label: 'Home Care Organization Inspection Checklist', name: 'apps-hr-form13' },
      { label: 'Personnel Record (Admin)', name: 'apps-hr-form14a' },
      { label: 'Personnel Record', name: 'apps-hr-form14b' },
      { label: 'Applicant for Home Care Aide Registration', name: 'apps-hr-form14c' },
      { label: 'Criminal Record Statement', name: 'apps-hr-form15' },
      { label: 'Statement Acknowledging Requirement to Report Suspected Abuse of Dependent Adults and Elders', name: 'apps-hr-form16' },
      { label: 'Registered Home Care Aide Training Log A', name: 'apps-hr-form17a' },
      { label: 'Registered Home Care Aide Training Log B', name: 'apps-hr-form17b' },
      { label: 'Workplace Safety', name: 'apps-hr-form18' },
      { label: 'Current Knowledge', name: 'apps-hr-form19' },
      { label: 'Fact Sheet (Home Care Services - April 2017)', name: 'apps-hr-form20' },
      { label: 'Acknowledgement Form - Fact Sheet (Home Care Services)', name: 'apps-hr-form21' },
      { label: 'Training Completion Certificate', name: 'apps-hr-form22' },
    ]

    const flatPickrConfig = {
      wrap: true,
      dateFormat: 'Y-m-d',
    }

    const sock = new SockJS('https://socket.motivit.com:443/echo')
    const sockId = 'coc-n01'

    return {
      form,

      fullName,
      applicationDate,
      nickname,
      gender,
      street,
      city,
      state,
      zip,
      homePhone,
      cellphone,
      emailAddress,
      emergencyContactPerson,
      emergencyContactNumber,
      dob,
      height,
      weight,
      maritalStatus,
      ssnTin,
      idType,
      idNo,
      idExp,
      stateIssued,
      educationAttachmentsFile,

      input,

      formUpdate,
      formUpdateStatus,

      formCopy,

      userData,

      formsOptions,

      flatPickrConfig,

      sock,
      sockId,
    }
  },
  data() {
    return {
      action: this.$route.params.action,
      id: this.$route.query.id,
      urlUpload: this.$urlUpload,

      isNotSaved: false,

      forms: this.$route.name,

      formUpdateChanges: [],

      required,
      email,
    }
  },
  computed: {
    ...mapState('app-hr', ['uploadPercentage']),
  },
  watch: {
    // eslint-disable-next-line func-names
    'formUpdate.data': function () {
      Object.entries(this.formUpdate.data).forEach(([key, val]) => {
        if (JSON.stringify(val) !== JSON.stringify(this.formCopy[key]) && (Number(val) !== Number(this.formCopy[key]) || String(val) !== String(this.formCopy[key]))) {
          this.formUpdateChanges.push(this.$refs[key].$attrs.title)
        }
      })
    },
  },
  created() {
    this.sock.onopen = () => {
      // console.log('Connection established.')
    }

    this.sock.onmessage = event => {
      const data = JSON.parse(event.data)
      if (data.id === this.sockId) {
        this.getSockMsg(data.msg)
      }
    }

    this.sock.onclose = () => {
      // console.warn('Connection closed.')
    }
  },
  methods: {
    getSockMsg(sockMsg) {
      if (sockMsg === true) {
        // Do something
      }
    },
    sendSockMsg(sockMsg) {
      this.sock.send(JSON.stringify({ id: this.sockId, msg: sockMsg }))
    },
    requestFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/requestFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 1

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    denyFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/denyFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          if (jsonObjectLiterals.data.id) {
            self.form = jsonObjectLiterals.data
          }

          self.educationAttachmentsFile = jsonObjectLiterals.data.educationAttachments

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    approveFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/approveFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    dismissFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/dismissFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    validationForm() {
      const self = this

      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let msg = 'Do you want to save the changes?'
          if (this.action === 'add') msg = 'Do you want to add these data?'
          Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: msg,
            confirmButtonText: 'Proceed',
            showCancelButton: true,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.isNotSaved = true

              store
                .dispatch(this.action === 'add' ? 'app-hr/addForm2' : 'app-hr/updateForm2', this.form)
                .then(response => {
                  // eslint-disable-next-line
                  const jsonObjectLiterals = response.data.response.data

                  this.form.id = jsonObjectLiterals.id

                  if (this.userData.role === 'agent' && this.action === 'edit' && jsonObjectLiterals.count > 0) {
                    self.requestFormUpdate()
                  }

                  Swal.fire({
                    icon: 'success',
                    title: 'Saved!',
                    text: 'Saved successfully.',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .catch(error => {
                  console.error(error)

                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .finally(() => {
                  self.isNotSaved = false
                })
            }
          })
        } else {
          Swal.fire({
            icon: 'error',
            // title: 'Are you sure?',
            // eslint-disable-next-line
            text: 'Please fill-up all of the required fields.',
            confirmButtonText: 'OK',
            showCancelButton: false,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          })
        }
      })
    },
    previewFormFile(params) {
      Swal.fire({
        text: params.item,
        padding: '1em 3em',
        // eslint-disable-next-line
        imageUrl: this.$urlUpload + params.id + '/' + params.item,
        imageAlt: params.item,
        showConfirmButton: false,
        showCloseButton: true,
        returnFocus: false,
      })
    },
    deleteFormFile(params) {
      const self = this

      Swal.fire({
        icon: 'warning',
        title: 'Are you sure?',
        // eslint-disable-next-line
        text: 'Do you want to delete ' + Object.values(params)[1] + '?',
        confirmButtonText: 'Proceed',
        showCancelButton: true,
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn btn-secondary',
          cancelButton: 'btn btn-outline-dark mr-1',
        },
        buttonsStyling: false,
        returnFocus: false,
      }).then(result => {
        if (result.isConfirmed) {
          store
            .dispatch('app-hr/deleteForm2File', params)
            .then(response => {
              // eslint-disable-next-line
              const jsonObjectLiterals = response.data.response.data

              // console.log(jsonObjectLiterals)

              // update object file
              const key = Object.keys(params)[1]
              self.form[key] = jsonObjectLiterals.data[key]
              self[`${key}File`] = jsonObjectLiterals.data[key]

              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                // eslint-disable-next-line
                text: 'Deleted succesfully.',
                showConfirmButton: false,
                timer: 1500,
              })
            })
            .catch(error => {
              console.error(error)

              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                showConfirmButton: false,
                timer: 1500,
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
  .custom-radioClass {
    margin-top: 6px;
  }
</style>

<style lang="scss" scoped>
.hr-forms-select {
  width: 100%;
  min-width: 182px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 135px;
  }
}

@media (max-width: 1199.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 112px;
    }
  }
}

@media (max-width: 991.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 598px;
    }
  }
}

@media (max-width: 767.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 406px;
    }
  }
}

@media (max-width: 575.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 352px;
    }
  }
}
</style>
